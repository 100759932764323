import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User } from './user-data.model';


@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  baseUrl = '/api/users/';

  constructor(private http: HttpClient) { }

  list(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl);
  }

  retrive(userId: number): Observable<User> {
    return this.http.get<User>(this.baseUrl + userId + '/');
  }

  create(userData: User): Observable<User> {
    return this.http.post<User>(this.baseUrl, userData);
  }

  update(userData: User): Observable<User> {
    return this.http.patch<User>(this.baseUrl + userData.id + '/', userData);
  }

  delete(userId: number) {
    return this.http.delete(this.baseUrl + userId + '/');
  }
}
