import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule, MatInputModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';

import { AppRoutingModule } from '../app-routing.module';

import { UserListComponent } from './user-list/user-list.component';
import { UserDataService } from './user-data.service';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserDeleteComponent } from './user-delete/user-delete.component';
import { UserPasswordChangeComponent } from './user-password-change/user-password-change.component';


@NgModule({
  declarations: [
    UserListComponent,
    UserCreateComponent,
    UserDeleteComponent,
    UserPasswordChangeComponent,
  ],
  imports: [
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule
  ],
  providers: [
    UserDataService
  ],
  entryComponents: [
    UserListComponent,
    UserCreateComponent,
    UserDeleteComponent,
    UserEditComponent,
    UserPasswordChangeComponent
  ]
})
export class UserDataModule { }
