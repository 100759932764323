import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialogRef } from '@angular/material';

import { AllergenService } from '../allergen.service';
import { AllergenType, allergenicityOptionsData } from '../allergen.model';



@Component({
  selector: 'app-allergen-create',
  templateUrl: './allergen-create.component.html',
  styleUrls: ['./allergen-create.component.scss']
})
export class AllergenCreateComponent implements OnInit {

  allergenCreateTitle = 'Унеси име алегена';
  allergenFormLabel = 'Име алергена';
  allergenLozalizedLable = 'Локализовано име';
  allergenMargineTopLable = 'Горња граница';
  allergenMargineBottomLable = 'Доња граница';
  allergenTypeLable = 'Тип';
  allergenicityLable = 'Алергеност';

  msgSuccess = 'Нови алерген успешно креиран';
  msgClose = 'Затвори';

  allergenCreateForm;
  allergenType: AllergenType[];
  allergenicityOptions = allergenicityOptionsData;

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public allergenService: AllergenService,
    public dialogRef: MatDialogRef<AllergenCreateComponent>,
  ) { }

  ngOnInit() {
    this.allergenService.type().subscribe(data => {
      this.allergenType = data;
    });

    this.allergenCreateForm = this.formBuilder.group({
      name: this.formBuilder.control('', Validators.required),
      localized_name: this.formBuilder.control('', Validators.required),
      margine_top: this.formBuilder.control(100, Validators.required),
      margine_bottom: this.formBuilder.control(60, Validators.required),
      type: this.formBuilder.control('', Validators.required),
      allergenicity: this.formBuilder.control(1, Validators.required),
    });
  }

  onSubmit(): void {
    if (this.allergenCreateForm.valid) {
      this.allergenService.create(this.allergenCreateForm.value).subscribe(data => {
          this.snackBar.open(this.msgSuccess, this.msgClose, {
            duration: 2000,
          });
          this.dialogRef.close(true);
        });
    }
  }

}
