import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { UserDataService } from '../user-data.service';
import { User } from '../user-data.model';


@Component({
  selector: 'app-user-data-edit',
  templateUrl: './user-password-change.component.html',
  styleUrls: ['./user-password-change.component.scss']
})
export class UserPasswordChangeComponent implements OnInit {

  msgSuccess = 'Лозинка успешно промењена';
  msgClose = 'Затвори';

  userData: User;
  groupList: Array<Location>;

  userPasswordChangeForm;

  constructor(
    private userDataService: UserDataService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UserPasswordChangeComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userData = data;
    console.log(this.userData);

  }

  ngOnInit() {
    this.userPasswordChangeForm = this.formBuilder.group({
      id: this.formBuilder.control(''),
      password: this.formBuilder.control(''),
    });

    this.userPasswordChangeForm.get('id').setValue(this.userData.id);
  }

  onSubmit() {
    this.userDataService.update(this.userPasswordChangeForm.value).subscribe((result) => {
      this.snackBar.open(this.msgSuccess, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

}
