import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';

import { UserDataService } from '../user-data.service';
import { Location } from '../../location/location.model';
import { LocationService } from '../../location/location.service';


@Component({
  selector: 'app-user-data-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  msgSuccess = 'Нови корисник успешно креиран';
  msgClose = 'Затвори';

  userCreateForm;
  groupList: Array<Location>;

  constructor(
    private userDataService: UserDataService,
    private locationService: LocationService,
    public dialogRef: MatDialogRef<UserCreateComponent>,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.userCreateForm = this.formBuilder.group({
      first_name: this.formBuilder.control(''),
      last_name: this.formBuilder.control(''),
      username: this.formBuilder.control(''),
      email: this.formBuilder.control(''),
      groups: this.formBuilder.control([]),
      is_superuser: this.formBuilder.control(false),
      password: this.formBuilder.control(''),
    });

    this.locationService.list().subscribe(data => this.groupList = data);
  }

  onSubmit() {
    this.userDataService.create(this.userCreateForm.value).subscribe((result) => {
      this.snackBar.open(this.msgSuccess, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }
}
