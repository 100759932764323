import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Trend } from './trend.model';

@Injectable({
  providedIn: 'root'
})
export class TrendService {
  baseUrl = '/api/trends/';

  constructor(private http: HttpClient) { }

  get(params): Observable<Trend[]> {
    return this.http.get<Trend[]>(this.baseUrl, {
      params: params
    });
  }

  list(params): Observable<Trend[]> {
    return this.http.get<Trend[]>(this.baseUrl, {
      params: params
    });
  }

  retrive(trendId: number): Observable<Trend> {
    return this.http.get<Trend>(this.baseUrl + trendId + '/');
  }

  create(params): Observable<Trend> {
    return this.http.post<Trend>(this.baseUrl, params);
  }

  update(trend: Trend): Observable<Trend> {
    return this.http.patch<Trend>(this.baseUrl + trend.id + '/', trend);
  }

  delete(trendId: number) {
    return this.http.delete(this.baseUrl + trendId + '/');
  }
}
