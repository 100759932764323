import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    currentUser = new Subject();
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isLoggedIn() {
        return this.loggedIn.asObservable();
      }

    get getUser(): Observable<any> {
        return this.currentUser.asObservable();
    }

    constructor(
        private router: Router,
        private http: HttpClient
    ) { }

    refresh() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.currentUser.next(currentUser);
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.loggedIn.next(true);
        }
    }

    login(username: string, password: string) {
        return this.http.post<any>(`/api/jwt/login`, { username, password })
            .pipe(map(user => {
                if (user && user.token) {
                    this.currentUser.next(user);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.loggedIn.next(true);
                }
                return user;
            }));
    }

    logout() {
        this.currentUser.next();
        this.loggedIn.next(false);
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
    }

}
