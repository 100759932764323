import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
  OnInit
} from '@angular/core';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { Location } from '../location/location.model';
import { PollenService } from '../pollen/pollen.service';
import { AllergenService } from '../allergen/allergen.service';


@Component({
  selector: 'app-pollen',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './pollen.component.html',
  styleUrls: ['./pollen.component.scss']
})
export class PollenComponent implements OnInit {

  @ViewChild('modalContent')

  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  activeDayIsOpen = true;

  selectedLocation: Location;
  currentUser;
  allergenData;

  constructor(
    private allergenService: AllergenService,
    public pollenService: PollenService,
  ) {};

  ngOnInit() {
    this.allergenService.list().subscribe(result => {
      this.allergenData = result;
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  locationEvent(location) {
    this.selectedLocation = location;
    this.weekStartsOn = location.week_start;
  }

}
