import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { MatSnackBar, MatDialogRef } from '@angular/material';

import { LocationService } from '../location.service';


@Component({
  selector: 'app-location-create',
  templateUrl: './location-create.component.html',
  styleUrls: ['./location-create.component.scss']
})
export class LocationCreateComponent implements OnInit {

  locationCreateTitle = 'Унеси нову локацију';
  locationFormNameLabel = 'Име локације';
  locationFormShortNameLabel = 'Скраћено име';

  msgSuccess = 'Нова локација успешно креирана';
  msgClose = 'Затвори';

  locationCreateForm;
  maxShortNameLength = 2;

  WEEK_START = [
    {"id": 0, "name": "Недеља"},
    {"id": 1, "name": "Понедељак"},
    {"id": 2, "name": "Уторак"},
    {"id": 3, "name": "Среда"},
    {"id": 4, "name": "Четвртак"},
    {"id": 5, "name": "Петак"},
    {"id": 6, "name": "Субота"}
  ];

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public locationService: LocationService,
    public dialogRef: MatDialogRef<LocationCreateComponent>,
  ) { }

  ngOnInit() {
    this.locationCreateForm = this.formBuilder.group({
      name: this.formBuilder.control('', Validators.required),
      short_name: this.formBuilder.control('', Validators.required),
      latitude: this.formBuilder.control(0, Validators.required),
      longitude: this.formBuilder.control(0, Validators.required),
      description: this.formBuilder.control(''),
      week_start: this.formBuilder.control(1),
    });
  }

  onSubmit(): void {
    if (this.locationCreateForm.valid) {
      this.locationService.create(this.locationCreateForm.value).subscribe(data => {
          this.snackBar.open(this.msgSuccess, this.msgClose, {
            duration: 2000,
          });
          this.dialogRef.close(true);
        });
    }
  }

  compareWeekDayFn(option, week_day): boolean {
    return option === week_day;
  }
}
