import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { Location } from '../location.model';
import { LocationService } from '../../location/location.service';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent implements OnInit {
  msgSuccess = 'Промене успешно сачуване';
  msgClose = 'Затвори';

  location: Location;
  locationEditForm: FormGroup;

  WEEK_START = [
    {"id": 0, "name": "Недеља"},
    {"id": 1, "name": "Понедељак"},
    {"id": 2, "name": "Уторак"},
    {"id": 3, "name": "Среда"},
    {"id": 4, "name": "Четвртак"},
    {"id": 5, "name": "Петак"},
    {"id": 6, "name": "Субота"}
  ];

  constructor(
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LocationEditComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.location = data;
  }

  ngOnInit() {
    this.locationEditForm = this.formBuilder.group({
      id: this.formBuilder.control(0),
      name: this.formBuilder.control(''),
      short_name: this.formBuilder.control(''),
      latitude: this.formBuilder.control(''),
      longitude: this.formBuilder.control(''),
      description: this.formBuilder.control(''),
      week_start: this.formBuilder.control(''),
    });

    this.locationEditForm.patchValue(this.location);
  }

  onSubmit() {
    this.locationService.update(this.locationEditForm.value).subscribe((result) => {
      this.snackBar.open(this.msgSuccess, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  compareWeekDayFn(option, week_day): boolean {
    return option === week_day;
  }

}
