import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from './location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  baseUrl = '/api/locations/';

  constructor(private http: HttpClient) { }

  list(): Observable<Location[]> {
    return this.http.get<Location[]>(this.baseUrl);
  }

  retrive(location_id: number): Observable<Location> {
    return this.http.get<Location>(this.baseUrl + location_id + '/');
  }

  create(value): Observable<Location> {
    return this.http.post<Location>(this.baseUrl, value);
  }

  update(location: Location): Observable<Location> {
    return this.http.patch<Location>(this.baseUrl + location.id + '/', location);
  }

  delete(location_id: number) {
    return this.http.delete(this.baseUrl + location_id + '/');
  }
}
