export class AllergenType {
    public id: number;
    public name: string;
}

export class Allergen {
    public id: number;
    public name: string;
    public representation_name: string;
    public localized_name: string;
    public margine_top: number;
    public margine_bottom: number;
    public type: AllergenType;
    public allergenicity: number;
}

export class AllergenicityOption {
    public value: number;
    public name: string;
}

export const allergenicityOptionsData: AllergenicityOption[] = [
    {value: 1, name: 'Мала'},
    {value: 2, name: 'Средња'},
    {value: 3, name: 'Велика'},
  ];
