import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { Allergen, AllergenType, AllergenicityOption, allergenicityOptionsData } from '../allergen.model';
import { AllergenService } from '../allergen.service';

@Component({
  selector: 'app-allergen-edit',
  templateUrl: './allergen-edit.component.html',
  styleUrls: ['./allergen-edit.component.scss']
})
export class AllergenEditComponent implements OnInit {

  allergenCreateTitle = 'Име алегена';
  allergenFormLabel = 'Име алергена';
  allergenLozalizedLable = 'Локализовано име';
  allergenMargineTopLable = 'Горња граница';
  allergenMargineBottomLable = 'Доња граница';
  allergenTypeLable = 'Тип';
  allergenicityLable = 'Алергеност';

  msgSuccess = 'Промене успешно сачуване';
  msgClose = 'Затвори';

  allergen: Allergen;
  allergenEditForm: FormGroup;
  allergenType: AllergenType[];
  allergenicityOptions: AllergenicityOption[] = allergenicityOptionsData;

  constructor(
    private allergenService: AllergenService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AllergenEditComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.allergen = data;
  }

  ngOnInit() {
    this.allergenService.type().subscribe(data => {
      this.allergenType = data;
    });

    this.allergenEditForm = this.formBuilder.group({
      id: this.formBuilder.control(0),
      name: this.formBuilder.control('', Validators.required),
      localized_name: this.formBuilder.control('', Validators.required),
      margine_top: this.formBuilder.control(100, Validators.required),
      margine_bottom: this.formBuilder.control(60, Validators.required),
      type: this.formBuilder.control(0, Validators.required),
      allergenicity: this.formBuilder.control(1, Validators.required),
    });

    this.allergenEditForm.patchValue(this.allergen);
  }

  onSubmit() {
    this.allergenService.update(this.allergenEditForm.value).subscribe((result) => {
      this.snackBar.open(this.msgSuccess, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  compareTypeFn(option: string, type: string): boolean {
    return option === type;
  }

  compareAllergenicityFn(option: number, allergenicity: number): boolean {
    return option === allergenicity;
  }

}
