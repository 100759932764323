import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { Location } from '../location.model';
import { LocationService } from '../location.service';

@Component({
  selector: 'app-location-delete',
  templateUrl: './location-delete.component.html',
  styleUrls: ['./location-delete.component.scss']
})
export class LocationDeleteComponent implements OnInit {

  deleteTitle = 'Брисање локације!';
  deleteContent = 'Потврдите акцију.';
  buttonDelete = 'Обриши';
  buttonClose = 'Затвори';

  msgDelete = 'Локација успешно обрисана';
  msgClose = 'Затвори';

  location: Location;

  constructor(
    private locationService: LocationService,
    public dialogRef: MatDialogRef<LocationDeleteComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.location = data;
  }

  ngOnInit() { }

  onDelete() {
    this.locationService.delete(this.location.id).subscribe((response) => {
      this.snackBar.open(this.msgDelete, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

}
