import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Chart } from 'chart.js';
import { PollenService } from '../pollen.service';

import { Location } from '../../location/location.model';
import { Allergen } from '../../allergen/allergen.model';
import { AllergenService } from '../../allergen/allergen.service';
import { LocationService } from '../../location/location.service';


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  chart;
  cities: Location[];
  allergens: Allergen[];
  selectedAllergens: Allergen[];
  chartForm: FormGroup;

  endDate = new Date();
  startDate = new Date(this.endDate.getFullYear(), (this.endDate.getMonth() - 3), this.endDate.getDate());

  constructor(
    private pollenService: PollenService,
    private allergenService: AllergenService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.chartForm = this.formBuilder.group({
      startDate: this.formBuilder.control(this.startDate, Validators.required),
      endDate: this.formBuilder.control(this.endDate, Validators.required),
      selectedAllergens: this.formBuilder.control(''),
    });

    this.allergenService.list().subscribe(data => {
      this.allergens = data;
    });

    this.locationService.list().subscribe(cities => this.cities = cities);

  }

  onSubmit() {
    if (!this.chartForm.valid) { return; }
    if (this.chart) { this.chart.destroy() };

    const formValues = this.chartForm.value;
    const params = {
      date_after: formatDate(formValues.startDate, 'yyyy-MM-dd', 'en'),
      date_before: formatDate(formValues.endDate, 'yyyy-MM-dd', 'en'),
    };

    if (formValues.selectedAllergens) {
      params["allergen_ids"] = formValues.selectedAllergens.map(item => item.id)
    }

    this.pollenService.chart(params).subscribe(pollenData => {

      const labels = this.cities.map(item => item.short_name);
      let datasets = [];

      const selectedAllergenOptions = formValues.selectedAllergens.length > 0 ? formValues.selectedAllergens : this.allergens;
      for (const option of selectedAllergenOptions) {
        const color = this.getRandomColor();
        let values = [];
        for (const location of this.cities) {
          let allergenData = pollenData.filter(item => item.concentrations__allergen === option.id && item.location === location.id).map(item => item.over_margine_bottom);

          values.push(allergenData[0] ? allergenData[0] : 0)

        }
        const allergen = this.allergens.filter(item => item.id === option.id)[0];
        const label = allergen.name + ' - ' + allergen.localized_name;

        datasets.push({
          data: values,
          label: label,
          backgroundColor: color,
          borderColor: color,
        });

      }

      this.chart = new Chart('canvas', {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true
              }
            }],
          }
        }
      });

    });

  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

}
