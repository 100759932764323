import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pollen, Concentration, PollenChart, PollenMsg } from './pollen.model';

@Injectable({
  providedIn: 'root'
})
export class PollenService {
  baseUrl = '/api/pollens/';

  constructor(private http: HttpClient) { }

  get(params): Observable<Pollen[]> {
    return this.http.get<Pollen[]>(this.baseUrl, {
      params: params
    });
  }

  list(params): Observable<Pollen[]> {
    return this.http.get<Pollen[]>(this.baseUrl, {
      params: params
    });
  }

  chart(params): Observable<PollenChart[]> {
    return this.http.get<PollenChart[]>(this.baseUrl + 'chart/', {
      params: params
    });
  }

  export(params) {
    return this.http.get(this.baseUrl + 'export/', {
      params: params,
      responseType: 'blob',
    });
  }

  retrive(pollenId: number): Observable<Pollen> {
    return this.http.get<Pollen>(this.baseUrl + pollenId + '/');
  }

  create(params): Observable<Pollen> {
    return this.http.post<Pollen>(this.baseUrl, params);
  }

  update(pollen: Pollen): Observable<Pollen> {
    return this.http.patch<Pollen>(this.baseUrl + pollen.id + '/', pollen);
  }

  delete(pollenId: number) {
    return this.http.delete(this.baseUrl + pollenId + '/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConcentrationService {
  baseUrl = '/api/concentrations/';

  constructor(private http: HttpClient) { }

  create(params): Observable<Concentration> {
    return this.http.post<Concentration>(this.baseUrl, params);
  }

  update(concentration): Observable<Concentration> {
    return this.http.patch<Concentration>(this.baseUrl + concentration.id + '/', concentration);
  }

}
