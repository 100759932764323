import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatDialog, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';

import { Location } from '../location.model';
import { LocationService } from '../location.service';

import { LocationCreateComponent } from '../location-create/location-create.component';
import { LocationEditComponent } from '../location-edit/location-edit.component';
import { LocationDeleteComponent } from '../location-delete/location-delete.component';


@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {
  locationListTitle = 'Локације мерних станица';
  addLocation = 'Додај нову локацију';
  headerNo = 'БР.';
  headerLocationName = 'Име локације';
  headerLocationShortName = 'Скраћено име';
  headerLocationLatitude = 'Географска ширина';
  headerLocationLongitude = 'Географска дужина';
  headerLocationWeekStart = 'Недеља почиње у';
  headerEdit = 'Уреди';
  headerDelete = 'Обриши';
  placeholderFilter = 'Претрага';

  msgDelete = 'Локација успешно обрисана';
  msgClose = 'Затвори';

  displayedColumns: string[] = ['name', 'short_name', 'latitude', 'longitude', 'week_start_str', 'edit', 'delete'];

  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private locationService: LocationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.locationService.list().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(LocationCreateComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openEditDialog(location: Location): void {
    const dialogRef = this.dialog.open(LocationEditComponent, {
      width: '350px', data: location
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openDeleteDialog(location: Location): void {
    const dialogRef = this.dialog.open(LocationDeleteComponent, {
      width: '150px', data: location
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  reload(): void {
    this.locationService.list().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
