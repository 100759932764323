import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatDialog, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';

import { Allergen, allergenicityOptionsData } from '../allergen.model';
import { AllergenService } from '../allergen.service';

import { AllergenCreateComponent } from '../allergen-create/allergen-create.component';
import { AllergenEditComponent } from '../allergen-edit/allergen-edit.component';
import { AllergenDeleteComponent } from '../allergen-delete/allergen-delete.component';


@Component({
  selector: 'app-allergen-list',
  templateUrl: './allergen-list.component.html',
  styleUrls: ['./allergen-list.component.scss']
})
export class AllergenListComponent implements OnInit {
  аllergenListTitle = 'Алергени';
  addAllergen = 'Додај нови алерген';
  headerNo = 'БР.';
  headerАllergenName = 'Име алергена';
  headerEdit = 'Уреди';
  headerDelete = 'Обриши';
  placeholderFilter = 'Претрага';

  msgDelete = 'Алерген успешно обрисан';
  msgClose = 'Затвори';

  displayedColumns: string[] = ['name', 'localized_name', 'margine_top', 'margine_bottom', 'type', 'allergenicity', 'edit', 'delete'];

  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private аllergenService: AllergenService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.аllergenService.list().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(AllergenCreateComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openEditDialog(аllergen: Allergen): void {
    const dialogRef = this.dialog.open(AllergenEditComponent, {
      width: '350px', data: аllergen
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openDeleteDialog(аllergen: Allergen): void {
    const dialogRef = this.dialog.open(AllergenDeleteComponent, {
      width: '150px', data: аllergen
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  reload(): void {
    this.аllergenService.list().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  findAllergenicityName(allergenicity): string {
    return allergenicityOptionsData.find(item => item.value === allergenicity).name;
  }

}
