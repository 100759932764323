import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule, MatInputModule, MatAutocompleteModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';


import { AppRoutingModule } from '../app-routing.module';

import { LocationComponent } from './location.component';
import { LocationService } from './location.service';
import { LocationCreateComponent } from './location-create/location-create.component';
import { LocationDeleteComponent } from './location-delete/location-delete.component';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationEditComponent } from './location-edit/location-edit.component';


@NgModule({
  declarations: [
    LocationComponent,
    LocationCreateComponent,
    LocationDeleteComponent,
    LocationListComponent,
    LocationEditComponent,
  ],
  imports: [
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule
  ],
  providers: [
    LocationService,
  ],
  entryComponents: [
    LocationCreateComponent,
    LocationDeleteComponent,
    LocationEditComponent,
  ],
  exports: [LocationComponent],
})
export class LocationModule { }
