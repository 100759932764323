import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';

import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Location } from './location.model';
import { LocationService } from './location.service';
import { LocationCreateComponent } from './location-create/location-create.component';
import { LocationDeleteComponent } from './location-delete/location-delete.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  newLocation = 'Додај локацију';
  deleteLocation = 'Обриши локацију';

  cities: Location[];
  selected: Location;

  locationFormControl: FormControl;

  filteredLocationOptions: Observable<Location[]>;

  @Output() messageEvent = new EventEmitter<string>();

  constructor(
    private locationService: LocationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.locationFormControl = new FormControl('', [Validators.required]);
    this.reload();
  }

  filterCities() {
    this.filteredLocationOptions = this.locationFormControl.valueChanges
      .pipe(
        startWith<string | Location>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterLocation(name) : this.cities.slice())
      );
  }

  filterLocation(name: string): Location[] {
    const found = this.cities.filter(option =>
      option.name.toLowerCase().indexOf(name.toLowerCase().trim()) === 0);
    if (found.length === 0) {
      this.locationFormControl.setErrors({ 'incorrect': true });
    }
    return found;
  }

  displayLocationFn(location?: Location): string | undefined {
    return location ? location.name : undefined;
  }

  onSelectChangeLocation(_event, location) {
    if (_event.isUserInput) {
      this.selected = location;
      this.messageEvent.emit(location);
    }
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(LocationCreateComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(LocationDeleteComponent, {
      width: '350px', data: this.selected
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.selected = null;
        this.messageEvent.emit('');
        this.locationFormControl = new FormControl('', [Validators.required]);
        this.reload();
      }
    });
  }

  reload() {
    this.locationService.list().subscribe(data => {
      this.cities = data;

      this.filterCities();
    });
  }

}
