import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { UserDataService } from '../user-data.service';
import { Location } from '../../location/location.model';
import { LocationService } from '../../location/location.service';
import { User } from '../user-data.model';


@Component({
  selector: 'app-user-data-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  msgSuccess = 'Промене успешно сачуване';
  msgClose = 'Затвори';

  userData: User;
  groupList: Array<Location>;

  userEditForm;

  constructor(
    private userDataService: UserDataService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UserEditComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userData = data;
  }

  ngOnInit() {
    this.userEditForm = this.formBuilder.group({
      id: this.formBuilder.control(''),
      first_name: this.formBuilder.control(''),
      last_name: this.formBuilder.control(''),
      username: this.formBuilder.control(''),
      email: this.formBuilder.control(''),
      groups: this.formBuilder.control([]),
      is_superuser: this.formBuilder.control(false),
    });

    this.userEditForm.patchValue(this.userData);
    this.locationService.list().subscribe(data => this.groupList = data);
  }

  onSubmit() {
    this.userDataService.update(this.userEditForm.value).subscribe((result) => {
      this.snackBar.open(this.msgSuccess, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  compareFn(userGpoup: number, locationGpoup: number): boolean {
    return userGpoup === locationGpoup;
  }
}
