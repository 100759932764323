import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Allergen, AllergenType } from './allergen.model';

@Injectable({
  providedIn: 'root'
})
export class AllergenService {
  baseUrl = '/api/allergens/';
  typeUrl = '/api/allergen-types/';

  constructor(private http: HttpClient) { }

  list(): Observable<Allergen[]> {
    return this.http.get<Allergen[]>(this.baseUrl);
  }

  retrive(allergen_id: number): Observable<Allergen> {
    return this.http.get<Allergen>(this.baseUrl + allergen_id + '/');
  }

  create(value): Observable<Allergen> {
    return this.http.post<Allergen>(this.baseUrl, value);
  }

  update(allergen: Allergen): Observable<Allergen> {
    return this.http.patch<Allergen>(this.baseUrl + allergen.id + '/', allergen);
  }

  delete(allergen_id: number) {
    return this.http.delete(this.baseUrl + allergen_id + '/');
  }

  type(): Observable<AllergenType[]> {
    return this.http.get<Allergen[]>(this.typeUrl);
  }
}
