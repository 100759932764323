import { Component, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { AllergenService } from '../../allergen/allergen.service';
import { PollenService, ConcentrationService } from '../../pollen/pollen.service';

@Component({
  selector: 'app-pollen-create',
  templateUrl: './pollen-create.component.html',
  styleUrls: ['./pollen-create.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' }
  ],
})
export class PollenCreateComponent {
  data;
  pollen;

  createForm: FormGroup;

  msgSuccess = 'Унос успешно сачуван';
  msgClose = 'Затвори';

  constructor(
    private formBuilder: FormBuilder,
    private allergenService: AllergenService,
    private pollenService: PollenService,
    private concentrationService: ConcentrationService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PollenCreateComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;

    this.createForm = this.formBuilder.group({
      pollen: this.formBuilder.array([]),
    });

    const params = {
      location: this.data.location.id,
      date: formatDate(this.data.date, 'yyyy-MM-dd', 'en')
    }

    this.pollenService.get(params).subscribe(result => {
      if (result.length === 0 ) {
        this.pollenService.create({
          location: this.data.location.id,
          date: formatDate(this.data.date, 'yyyy-MM-dd', 'en')
        }).subscribe(pollenData => {
          this.pollen = pollenData;
          this.setAllergens();
        });
      } else {
        this.pollen = result[0];
        this.setAllergens();
      }
    });

  }

  setAllergens() {
    const controls = <FormArray>this.createForm.controls.pollen;
    this.allergenService.list().subscribe(allergenData => {
      for (const allergen of allergenData) {
        const found = this.pollen.concentrations.find(item => item.allergen === allergen.id);

        const group = this.formBuilder.group({
          id: found ? found.id : null,
          pollen: this.pollen.id,
          value: found ? found.value : null,
          allergen: allergen.id,
          allergen_name: allergen.name,
        });

        group.valueChanges.pipe(
          distinctUntilChanged((a, b) => jsonEqual(a, b)),
          debounceTime(300)
        ).subscribe(element => {
          if (element.id !== null) {
            this.concentrationService.update(element).subscribe(result => result);
          }
        });
        controls.push(group);
      }
    });

  }

  onSubmit() {
    const changed = this.createForm.get('pollen')['controls'].filter(item => item.dirty);
    const newItems = changed.filter(item => item.value.id === null);

    const createValues = newItems.map(item => item.value);

    this.concentrationService.create(createValues).subscribe(result => {
      this.snackBar.open(this.msgSuccess, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

}

export function jsonEqual(a, b): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
