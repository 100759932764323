import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule, MatInputModule, MatNativeDateModule, MatDatepickerModule, MatAutocompleteModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from '../app-routing.module';

import { PollenComponent } from './pollen.component';
import { LocationModule } from '../location/location.module';
import { CalendarComponent } from './calendar/calendar.component';
import { PollenCreateComponent } from './pollen-create/pollen-create.component';
import { ChartComponent } from './chart/chart.component';
import { PollenExportComponent } from './export/export.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    PollenComponent,
    CalendarComponent,
    PollenCreateComponent,
    ChartComponent,
    PollenExportComponent
  ],
  imports: [
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatGridListModule,
    LocationModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModalModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
  entryComponents: [CalendarComponent, PollenCreateComponent, ]
})
export class PollenModule { }
