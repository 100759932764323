import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PollenService } from '../pollen.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class PollenExportComponent implements OnInit {

  loading = false;
  endDate = new Date();
  startDate = new Date(this.endDate.getFullYear(), (this.endDate.getMonth() - 3), this.endDate.getDate());

  exportForm: FormGroup;

  constructor(
    private pollenService: PollenService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.exportForm = this.formBuilder.group({
      startDate: this.formBuilder.control(this.startDate, Validators.required),
      endDate: this.formBuilder.control(this.endDate, Validators.required),
    });
  }

  onSubmit() {
    if (!this.exportForm.valid) { return; }
    this.loading = true;

    const data = this.exportForm.value;
    const params = {
      date_after: formatDate(data.startDate, 'yyyy-MM-dd', 'en'),
      date_before: formatDate(data.endDate, 'yyyy-MM-dd', 'en'),
    };

    this.pollenService.export(params).subscribe(res => {
      saveAs(res, 'podaci.xlsx',
     { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
     this.loading = false;
    });

  }

}
