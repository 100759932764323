import { Component, DoCheck, ChangeDetectorRef, Optional, Input, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Subject } from 'rxjs';
import { DateAdapter, CalendarUtils, CalendarWeekViewComponent, DAYS_OF_WEEK } from 'angular-calendar';
import { PollenService } from '../../pollen/pollen.service';
import { PollenCreateComponent } from '../pollen-create/pollen-create.component';
import { TrendService } from '../../pollen/trend.service';
import { Allergen } from 'src/app/allergen/allergen.model';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent extends CalendarWeekViewComponent implements DoCheck {
  @Input() selectedLocation;
  @Input() allergenData;
  currentUser;
  tableColumns = [];
  displayedColumns = [];
  pollenList = [];

  dataSource;
  refresh: Subject<any> = new Subject();

  selectedLocationDiffer;
  selectedDayDiffer;

  trend_options = [
    { 'lable': '---', 'value': 0 },
    { 'lable': 'У опадању', 'value': 1 },
    { 'lable': 'Без промене', 'value': 2 },
    { 'lable': 'У порасту', 'value': 3 }
  ];
  trends = [];

  constructor(
    cdr: ChangeDetectorRef,
    utils: CalendarUtils,
    @Optional() locale: string,
    dateAdapter: DateAdapter,
    private pollenService: PollenService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private trendService: TrendService
  ) {
    super(cdr, utils, locale, dateAdapter);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  openCreateDialog(dateStr: string): void {
    const dialogRef = this.dialog.open(PollenCreateComponent, {
      width: '900px',
      data: {
        location: this.selectedLocation,
        date: dateStr
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.update(); }
    });
  }

  ngDoCheck(): void {
    const locationChanges = this.selectedLocationDiffer !== this.selectedLocation;
    const daysChanges = this.selectedDayDiffer !== this.days[0].date.getTime();
    if ((locationChanges || daysChanges) && this.selectedLocation) {
      this.selectedLocationDiffer = this.selectedLocation;
      this.selectedDayDiffer = this.days[0].date.getTime();
      this.update();
    }
  }

  updateHeaders() {
    this.tableColumns = [{ name: 'representation_name', label: 'АЛЕРГЕНИ' },];
    this.days.map(day => {

      const dateStr = formatDate(day.date, 'yyyy-MM-dd', 'en');
      const pollen = this.pollenList.find(item => item.date === dateStr);

      this.tableColumns.push({
        verified: pollen ? pollen.verified : undefined,
        name: dateStr,
        label: formatDate(day.date, 'dd/MM/yyyy', 'en'),
        date: day.date
      });

    });

    this.tableColumns.push(
      { name: 'sum_allergen_values', label: 'Σ' },
    );

    if (this.currentUser.user.is_superuser) {
      this.tableColumns.push(
        { name: 'trend', label: 'ТЕНДЕНЦИЈА' }
      );
    }

    this.displayedColumns = this.tableColumns.map(column => column.label);
  }

  update(): void {

    this.trendService.list({
      week: this.getNumberOfWeek(this.days[0]),
      location: this.selectedLocation.id
    }
    ).subscribe(trendData => {
      this.trends = trendData;

      const params = {
        location: this.selectedLocation.id,
        date_after: formatDate(this.days[0].date, 'yyyy-MM-dd', 'en'),
        date_before: formatDate(this.days[this.days.length - 1].date, 'yyyy-MM-dd', 'en')
      };
      this.pollenService.list(params).subscribe(pollenData => {

        this.pollenList = pollenData;

        let data = this.allergenData.map(x => Object.assign({}, x));

        for (const allergen of data) {
          allergen['sum_allergen_values'] = 0;
          allergen['trend'] = 0;

          for (const pollen of pollenData) {
            const concentrationFound = pollen.concentrations.find(item => item.allergen === allergen.id);
            if (concentrationFound) {
              allergen[pollen.date] = concentrationFound.value;
              allergen['sum_allergen_values'] += concentrationFound.value;
            }
          }

          const trendFound = this.trends.find(item => item.allergen === allergen.id);
          if (trendFound) {
            allergen['trend'] = trendFound.value;
          }

        }

        let total = { 'representation_name': 'Σ' };
        let controls = { 'representation_name': '' };
        this.days.map(day => {
          const dayStr = formatDate(day.date, 'yyyy-MM-dd', 'en');
          const pollen = pollenData.find(item => item.date === dayStr);
          total[dayStr] = pollen ? pollen.concentrations.reduce((acc, item) => acc + item.value, 0) : 0;
        });

        controls['isControlsRow'] = true;
        data.unshift(controls);
        total['isTotalsRow'] = true;
        data.push(total);

        this.updateHeaders();
        this.dataSource = new MatTableDataSource(data);
        this.refresh.next();
      });
    });
  }

  lock(date: Date) {
    const dateStr = formatDate(date, 'yyyy-MM-dd', 'en');
    const pollen = this.pollenList.find(item => item.date === dateStr && item.location === this.selectedLocation.id);
    pollen.status = 2;
    this.pollenService.update(pollen).subscribe(result => { this.update(); });
  }

  unlock(date: Date) {
    const dateStr = formatDate(date, 'yyyy-MM-dd', 'en');
    const pollen = this.pollenList.find(item => item.date === dateStr && item.location === this.selectedLocation.id);
    pollen.status = 1;
    this.pollenService.update(pollen).subscribe(result => { this.update(); });
  }

  onTrendChange(_event: any, allergen: Allergen, option: any) {
    if (!_event.isUserInput) { return; }

    let trendFound = this.trends.find(item => item.allergen === allergen.id)

    if (!trendFound && option.value !== 0) {
      this.trendService.create(
        {
          allergen: allergen.id,
          value: option.value,
          location: this.selectedLocation.id,
          week: this.getNumberOfWeek(this.days[0])
        }
      ).subscribe(result => { this.trends.push(result) });
      return;
    }

    if (trendFound && trendFound.value === option.value) { return; }

    if (trendFound && option.value === 0) {
      this.trendService.delete(trendFound.id).subscribe(result => {
        let index = this.trends.indexOf(item => item.id === trendFound.id);
        this.trends.splice(index, 1);
        return;
      });
    }

    if (trendFound && trendFound.value !== option.value && option.value !== 0) {
      let data = Object.assign({}, trendFound);
      data.value = option.value

      this.trendService.update(data).subscribe(result => {
        this.trends.filter(item => item === trendFound);
        this.trends.push(result);
      });
      return;
    }
  }

  getNumberOfWeek(date) {
    let dateTmp:any = new Date(date.date.getTime());
    dateTmp.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    dateTmp.setDate(dateTmp.getDate() + 3 - (dateTmp.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(dateTmp.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    let week = 1 + Math.round(((dateTmp.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    console.log(week);

    return week;
  }
}
