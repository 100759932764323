import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule, MatInputModule, MatAutocompleteModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';

import { AppRoutingModule } from '../app-routing.module';

import { AllergenService } from './allergen.service';
import { AllergenListComponent } from './allergen-list/allergen-list.component';
import { AllergenEditComponent } from './allergen-edit/allergen-edit.component';
import { AllergenDeleteComponent } from './allergen-delete/allergen-delete.component';
import { AllergenCreateComponent } from './allergen-create/allergen-create.component';

@NgModule({
  declarations: [
    AllergenListComponent,
    AllergenEditComponent,
    AllergenDeleteComponent,
    AllergenCreateComponent
  ],
  imports: [
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule
  ],
  providers: [
    AllergenService,
  ],
  entryComponents: [
    AllergenEditComponent,
    AllergenDeleteComponent,
    AllergenCreateComponent,
  ],
  exports: [],
})
export class AllergenModule { }
