import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from '../../_services/authentication.service';


@Component({
  selector: 'app-nav',
  templateUrl: './appnav.component.html',
  styleUrls: ['./appnav.component.css']
})
export class AppnavComponent implements OnDestroy {
  currentUser;
  subscription: Subscription;

  constructor(
    public authService: AuthenticationService,
  ) {
    this.subscription = this.authService.getUser.subscribe(user => {
      setTimeout(() => this.currentUser = user, 0);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
