import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { User } from '../user-data.model';
import { UserDataService } from '../user-data.service';


@Component({
  selector: 'app-user-data-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})
export class UserDeleteComponent implements OnInit {

  deleteTitle = 'Брисање корисника!';
  deleteContent = 'Потврдите акцију.';
  msgDelete = 'Обриши';
  msgClose = 'Затвори';

  userData: User;

  constructor(
    private userDataService: UserDataService,
    public dialogRef: MatDialogRef<UserDeleteComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userData = data;
  }

  ngOnInit() { }

  onDelete() {
    this.userDataService.delete(this.userData.id).subscribe((response) => {
      this.snackBar.open(this.msgDelete, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

}
