import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { UserListComponent } from './user-data/user-list/user-list.component';
import { PollenComponent } from './pollen/pollen.component';
import { ChartComponent } from './pollen/chart/chart.component';
import { LocationListComponent } from './location/location-list/location-list.component';
import { AllergenListComponent } from './allergen/allergen-list/allergen-list.component';
import { PollenExportComponent } from './pollen/export/export.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'korisnici',
    component: UserListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'polen',
    component: PollenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'polen/pregled',
    component: ChartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'polen/izvoz',
    component: PollenExportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'polen/lokacije',
    component: LocationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'polen/alergeni',
    component: AllergenListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
