import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { Allergen } from '../allergen.model';
import { AllergenService } from '../allergen.service';

@Component({
  selector: 'app-allergen-delete',
  templateUrl: './allergen-delete.component.html',
  styleUrls: ['./allergen-delete.component.scss']
})
export class AllergenDeleteComponent implements OnInit {

  deleteTitle = 'Брисање алергена!';
  deleteContent = 'Потврдите акцију.';
  buttonDelete = 'Обриши';
  buttonClose = 'Затвори';

  msgDelete = 'Алерген успешно обрисан';
  msgClose = 'Затвори';

  allergen: Allergen;

  constructor(
    private allergenService: AllergenService,
    public dialogRef: MatDialogRef<AllergenDeleteComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.allergen = data;
  }

  ngOnInit() {
  }

  onDelete() {
    this.allergenService.delete(this.allergen.id).subscribe((response) => {
      this.snackBar.open(this.msgDelete, this.msgClose, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

}
