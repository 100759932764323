import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatDialog, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';

import { User } from '../user-data.model';
import { UserDataService } from '../user-data.service';

import { UserCreateComponent } from '../user-create/user-create.component';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { UserPasswordChangeComponent } from '../user-password-change/user-password-change.component';
import { UserDeleteComponent } from '../user-delete/user-delete.component';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent implements OnInit {
  userListTitle = 'Корисници';
  headerNo = 'БР.';
  headerUsername = 'Корисничко име';
  headerFirstName = 'Име';
  headerLastName = 'Презиме';
  headerAdmin = 'Аминистратор';
  headerEdit = 'Уреди';
  headerPasswordChange = 'Промени лозинку';
  headerDelete = 'Обриши';
  placeholderFilter = 'Претрага';
  addUser = 'Нови корисник';

  msgDelete = 'Корисник успешно обрисан';
  msgClose = 'Затвори';

  displayedColumns: string[] = ['id', 'username', 'first_name', 'last_name', 'is_superuser', 'edit', 'password_change', 'delete'];

  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userDataService: UserDataService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userDataService.list().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(UserCreateComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openEditDialog(userData: User): void {
    const dialogRef = this.dialog.open(UserEditComponent, {
      width: '350px', data: userData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openPasswordChangeDialog(userData: User): void {
    const dialogRef = this.dialog.open(UserPasswordChangeComponent, {
      width: '350px', data: userData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  openDeleteDialog(userData: User): void {
    const dialogRef = this.dialog.open(UserDeleteComponent, {
      width: '150px', data: userData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(); }
    });
  }

  reload(): void {
    this.userDataService.list().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}

